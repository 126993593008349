const CarParts = [
  'bumper_back',
  'bumper_front',
  'door_back_left',
  'door_back_right',
  'door_front_left',
  'door_front_right',
  'fender_back_left',
  'fender_back_right',
  'fender_front_left',
  'fender_front_right',
  'fog_light_back_left',
  'fog_light_back_right',
  'fog_light_front_left',
  'fog_light_front_right',
  'grill_low',
  'grill_radiator',
  'handle_back_left',
  'handle_back_right',
  'handle_front_left',
  'handle_front_right',
  'head_light_left',
  'head_light_right',
  'hood',
  'hubcap',
  'license_plate_back',
  'license_plate_front',
  'mirror_support',
  'mirror_left',
  'mirror_right',
  'pillar',
  'quarter_window_back_left',
  'quarter_window_back_right',
  'quarter_window_front_left',
  'quarter_window_front_right',
  'rocker_panel',
  'rocker_panel_left',
  'rocker_panel_right',
  'roof',
  'tail_light_left',
  'tail_light_right',
  'trunk',
  'wheel_back_left',
  'wheel_back_right',
  'wheel_front_left',
  'wheel_front_right',
  'window_back_left',
  'window_back_right',
  'window_corner_left',
  'window_corner_right',
  'window_front_left',
  'window_front_right',
  'windshield_back',
  'windshield_front',
];

export default CarParts;
